import { Controller } from "@hotwired/stimulus"
import { Modal } from 'bootstrap'

export default class extends Controller {
  connect() {
    const modalElement = document.getElementById('modal')

    this.modal = new Modal(modalElement)

    modalElement.classList.add('modal')
    this.modal.show()

    this.closeModal = this.closeModal.bind(this)
    this.disposeModal = this.disposeModal.bind(this)

    document.body.addEventListener('turbo:submit-end', this.closeModal)
    modalElement.addEventListener('hidden.bs.modal', this.disposeModal)
  }

  disconnect() {
    document.body.removeEventListener('turbo:submit-end', this.closeModal)
    document.getElementById('modal').removeEventListener('hidden.bs.modal', this.disposeModal)
  }

  closeModal(e) {
    if (e.detail.success) {
      this.modal.hide()
      this.disposeModal()
    } else {
      this.disposeModal()
    }
  }

  disposeModal(e) {
    if(e) { e.preventDefault() }
    // if (this.modal) { this.modal.dispose() }
    const modal = document.getElementById('modal')
    if (modal) {
      modal.src = ''
      modal.innerHTML = ''
      modal.classList.remove('modal')
    }
    const bodyElement = document.getElementsByTagName('body')[0]
    if(bodyElement && bodyElement.style) {
      bodyElement.style.overflow = null
    }
    const modalBackdrop = document.getElementsByClassName('modal-backdrop')[0]
    if (modalBackdrop) { modalBackdrop.remove() }
  }
}
