import "@hotwired/turbo-rails"
import "./controllers"
import "./components"

import '../assets/stylesheets/application.scss'

addEventListener("turbo:before-frame-render", (event) => {
  if (document.startViewTransition) {
    const originalRender = event.detail.render
    event.detail.render = (currentElement, newElement) => {
      document.startViewTransition(()=> originalRender(currentElement, newElement))
    }
  }
})
