import { Controller } from "@hotwired/stimulus"
import { get } from '@rails/request.js'

// Connects to data-controller="reservations"
export default class extends Controller {
  static targets = [ "menuWrapper" ]

  connect() {
    this.setContainerHeight()
  }

  async reloadForm() {
    // Create FormData object from the form element
    const formData = new FormData(this.element);

    // Convert FormData to URLSearchParams for easy query string creation
    const searchParams = new URLSearchParams();
    for (let [key, value] of formData) {
      searchParams.append(key, value);
    }

    searchParams.append('form_id', this.element.id)

    // Convert URLSearchParams to string
    const paramsString = searchParams.toString();
    console.log(paramsString); // Logs output like: "name=value&another_name=another_value"

    const response = await get(`${this.element.action}?${paramsString}`, {
      responseKind: 'turbo-stream',
      headers: {
        'Accept': 'text/vnd.turbo-stream.html',
      },
    })

    if (response.ok) {
      this.setContainerHeight()
    }
  }

  setContainerHeight() {
    const header = this.height('.header')
    const navbar = this.height('.navbar')
    const schedule = this.height('.schedule')
    const footer = document.querySelector('footer')?.getBoundingClientRect()?.height
    const body = document.querySelector('#menu-wrapper').getBoundingClientRect().height

    let height = body - header - schedule - navbar + 20
    if (footer) {
      height = height - footer
    }

    this.menuWrapperTarget.style.maxHeight = `${height}px`
  }

  height(selector) {
    const element = document.querySelector(selector)

    if (!element) return 0

    return element.getBoundingClientRect().height
  }
}
