import { Controller } from "@hotwired/stimulus"
import { ScrollSpy } from 'bootstrap'

export default class extends Controller {
  connect() {
    this.scrollSpy = new ScrollSpy(this.element, {
      target: '#navbar',
      // smoothScroll: true,
      threshold: [0.1, 0.1, 1, 1]
    })

    this.setContainerHeight()

    this.navbar = this.element.querySelector('#navbar')

    if (!this.navbar) return

    this.navbar.addEventListener('click', this.scrollToSection)
  }

  disconnect() {
    this.scrollSpy.dispose()

    if (!this.navbar) return

    this.navbar.removeEventListener('click', this.scrollToSection)
  }

  scrollToSection(e) {
    e.preventDefault()
    const targetHref = e.target.getAttribute('href')
    const target = document.querySelector(targetHref)
    target.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'start' })
  }

  setContainerHeight() {
    const header = this.height('.header')
    const head = this.height('.head')
    const navbar = this.height('#navbar')
    const footer = document.querySelector('footer')?.getBoundingClientRect()?.height
    const body = this.element.getBoundingClientRect().height

    let height = body - header - head - navbar + 20
    if (footer) {
      height = height - footer
    }

    this.element.querySelector('.container').style.maxHeight = `${height}px`
  }

  height(selector) {
    const element = this.element.querySelector(selector)

    if (!element) return 0

    return element.getBoundingClientRect().height
  }
}
