import { Application } from "@hotwired/stimulus"

const application = Application.start()

// Configure Stimulus development experience
application.warnings = true
application.debug    = false
window.Stimulus      = application

import { registerControllers } from 'moonshot-rails'
registerControllers(application)

import ReadMore from '@stimulus-components/read-more'
application.register('read-more', ReadMore)

export { application }
