import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['menu', 'button']

  connect() {
  }

  toggle(event) {
    event.preventDefault()
    if (this.menuTarget.classList.contains('drawer-on')) {
      this.menuTarget.classList.remove('drawer', 'drawer-start', 'drawer-on')
    } else {
      this.menuTarget.classList.add('drawer', 'drawer-start', 'drawer-on')
    }
  }

  hide(event) {
    if(this.hasButtonTarget && !this.buttonTarget.contains(event.target)) {
      this.menuTarget.classList.remove('drawer', 'drawer-start', 'drawer-on')
    }
  }
}
