import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [
    'switcher',
    'onePrice',
    'multiplePrices',
    'usesPriceVariants'
  ]

  toggle(e) {
    e.preventDefault()

    const onePrice = 'Switch to price variants'
    const multiplePrices = 'Switch to single price'

    if (this.switcherTarget.innerHTML === onePrice) {
      this.switcherTarget.innerHTML = multiplePrices
      this.onePriceTarget.classList.toggle('d-none')
      this.multiplePricesTarget.classList.toggle('d-none')
      this.usesPriceVariantsTarget.checked = true
    } else {
      this.switcherTarget.innerHTML = onePrice
      this.onePriceTarget.classList.toggle('d-none')
      this.multiplePricesTarget.classList.toggle('d-none')
      this.usesPriceVariantsTarget.checked = false
    }
  }
}
