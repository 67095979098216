import React, { useState } from 'react'
import Select from 'react-select'
import Creatable from 'react-select/creatable';

const MultiSelect = ({ inputName, options, selectedOptions, placeholder = 'Select...', menuPlacement = 'auto', errorMessage = null, creatable = false, multiSelect = false, clearable = false }) => {
  const [state, setState] = useState(() => {
    return {
      options,
      selectedOptions
    }
  })

  const onChange = values => {
    const selectedOptions = multiSelect ? values : (values ? [values] : [])
    setState(state => ({ ...state, selectedOptions }))
    const customEvent = new CustomEvent('input:change')
    window.dispatchEvent(customEvent)
  }

  const customStyles = {
    control: (provided, state) => {
      let border = errorMessage ? 'solid 1px #f95a53' : state.isFocused ? 'solid 1px #2684FF' : 'solid 1px #F8F6F2'

      return {
        ...provided,
        border: border,
        backgroundColor: '#F8F6F2',
        padding: '5px 10px',
        fontSize: '1.2rem',
        borderRadius: '0.95rem',
        color: '#716D66',
        fontWeight: '500',
        '&:hover': {
          border: border
        }
      }
    },
    option: (provided, state) => ({
      ...provided,
      ':hover': {
        backgroundColor: '#edf0ff'
      }
    }),
    singleValue: (provided, state) => {
      const opacity = state.exclusiveDisability ? 0.5 : 1
      const transition = 'opacity 300ms'

      return { ...provided, opacity, transition }
    },
    multiValue: (styles, { data }) => {
      return {
        ...styles,
        backgroundColor: '#edf0ff'
      }
    }
  }

  const SelectComponent = creatable ? Creatable : Select;

  return (
    <>
      <SelectComponent
        value={state.selectedOptions || []}
        options={state.options || []}
        onChange={values => onChange(values)}
        isOptionDisabled={option => option.disabled === true}
        placeholder={placeholder}
        isMulti={multiSelect}
        isClearable={clearable}
        styles={customStyles}
        menuPlacement={menuPlacement}
      />
      {state.selectedOptions && state.selectedOptions.length > 0 ? (
        <>
          {state.selectedOptions.map(value => (
            <input
              key={value.value}
              name={`${inputName}[]`}
              className='form-control hidden'
              type='hidden'
              value={value.value}
            />
          ))}
        </>
      ) : (
        <input
          key={0}
          name={`${inputName}[]`}
          className='form-control hidden'
          type='hidden'
          value={''}
      />
      )}
      {errorMessage && <div className='d-block invalid-feedback'>{errorMessage}</div>}
    </>
  )
}

export default MultiSelect
