import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = {
    template: String
  }

  add(e) {
    e.preventDefault()
    this.element.insertAdjacentHTML( 'beforeend', this.templateValue)
  }
}
