import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = {
    eventName: String,
    eventBody: String
  }

  connect() {
    const customEvent = new CustomEvent(this.eventNameValue, JSON.parse(this.eventBodyValue))

    window.dispatchEvent(customEvent)

    this.element.remove()
  }
}
