import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="field-toggle"
export default class extends Controller {
  static targets = ["field"]

  static values = {
    field: Array
  }

  connect() {
  }

  toggle(event) {
    if(this.hasFieldTarget && this.fieldValue.includes(event.target.value)) {
      this.fieldTarget.classList.remove("d-none")
    } else {
      this.fieldTarget.classList.add("d-none")
    }
  }
}
