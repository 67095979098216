import { Controller } from "@hotwired/stimulus"
import { Turbo } from '@hotwired/turbo-rails'

export default class extends Controller {
  static values = {
    url: String
  }

  connect() {
    if (this.urlValue) {
      if (this.urlValue == location.pathname) {
        location.reload()
      } else {
        Turbo.visit(this.urlValue)
      }

      this.element.remove()
    }
  }
}
