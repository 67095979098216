import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    setTimeout(() => {
      this.element.classList.add('pull-up')
      setTimeout(() => {
        this.element.remove()
      }, 1900)
    }, 2000)
  }

  dismiss() {
    this.element.remove()
  }
}
