import React, { useState } from "react";
import styled from 'styled-components';

import DotLoader from "react-spinners/DotLoader";

const ImageWrapper = styled.div`
  ${props => props.customStyle}
  border-radius: 5px 0 0 5px;
  display: flex;
  align-items: center;
  justify-content: center;
`

const Img = styled.img`
  height: 100%;
  width: 100%;
  object-fit: cover;
  ${props => props.$loading && `
    display: none;
  `
  }
`

export default function Image({ cdnUrl, customStyle = 'height: 100px; width: 100px;flex-shrink: 0;', callback = null }) {
  const [loading, setLoading] = useState(true)

  const loaded = () => {
    if (callback) { callback() }
    setLoading(false)
  }

  return (
    <ImageWrapper customStyle={customStyle}>
      <Img src={cdnUrl} $loading={loading} onLoad={loaded} />
      <DotLoader color="#19a66d" loading={loading} />
    </ImageWrapper>
  )
};
